<template>
  <div id="body">
    <!-- 广告展示量折线图开始 -->
    <div class="topBox">
      <div class="chartTitle">
        <p class="facility">广告展示量</p>
        <ul>
          <li v-for="(item, index) in dateList" :key="index" @click="Atpitch(index)"
            :class="currentIndex == index ? 'active' : ''">
            {{ item }}
          </li>
        </ul>
      </div>
      <div v-loading="dcLoading">
        <div id="main" style="height: 300px;"></div>
      </div>
    </div>
    <!-- 广告展示量折线图结束 -->
    <!-- 广告管理开始 -->
    <div class="bottomBox">
      <!-- 广告管理头 -->
      <div class="headerBox">
        <p>广告管理</p>
      </div>
      <!-- 广告管理搜索栏 -->
      <div class="query">
        <div class="queryLeft">
          <div>投放状态：
            <el-input class="input" v-model="serchList.putState" size="medium" placeholder="请输入投放状态"></el-input>
          </div>
          <div>计划名称：
            <el-select class="select" v-model="serchList.planName" @change="adverList(serchList,1,pagesize)"
              size="medium" placeholder="请选择计划名称">
              <el-option v-for="item in stateList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <br>
          <div>开始时间：
            <el-date-picker class="select" v-model="serchList.startTime" value-format="yyyy-MM-dd" placeholder="请选择开始时间"
              size="medium" @change="adverList(serchList,1,pagesize)">
            </el-date-picker>
          </div>
          <div>结束时间：
            <el-date-picker class="select" v-model="serchList.endTime" value-format="yyyy-MM-dd" placeholder="请选择结束时间"
              size="medium" @change="adverList(serchList,1,pagesize)">
            </el-date-picker>
          </div>
        </div>
        <div class="queryRight">
          <el-button @click="reset()">重置</el-button>
          <el-button type="primary" @click="adverList(serchList,1,pagesize)">查询</el-button>
        </div>
      </div>
      <!-- 广告管理列表 -->
      <el-table :data="adverData" v-loading="loading">
        <el-table-column prop="id" label="广告ID" align="center"></el-table-column>
        <el-table-column prop="advName" label="广告计划" align="center"></el-table-column>
        <el-table-column prop="status" label="暂停/开始" align="center">
          <template slot-scope="scope">
            <el-switch :value="scope.row.status == '2' ? start : end"
              :disabled="scope.row.status == '2' ? false : scope.row.status == '3' ? false : true"
              @change="val => changge(val,scope.row)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="stage" label="审核状态" align="center">
          <template slot-scope="scope">
            <span :style="scope.row.stage == '已通过' ? 'color:#67C23A' : scope.row.stage == '已拒绝' ? 'color:#F56C6C' : ''">
              {{scope.row.stage}}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="stage" label="起止日期" align="center" width="150">
          <template slot-scope="scope">
              {{scope.row.launchStartDay}}-{{scope.row.launchEndDay}}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="stage" label="投放时间段" align="center">
          <template slot-scope="scope">
              {{scope.row.launchStartHour}}-{{scope.row.launchEndHour}}
          </template>
        </el-table-column>
        <el-table-column label="每日预算" align="center">
          <template slot-scope="scope">
            <span>
            {{scope.row.everydayBudget == '1122334455' ? '不限' : $util.setMoney(scope.row.everydayBudget)}}</span>
            <i class="el-icon-edit iconClick" @click="budGetClick(scope.row)"></i>
          </template>
        </el-table-column>
        <el-table-column label="我的出价" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.singlePrice == '0' ? '不限' : $util.setMoney(scope.row.singlePrice)}}</span>
            <i class="el-icon-edit iconClick" @click="bidClick(scope.row)"></i>
          </template>
        </el-table-column>
        <el-table-column prop="actualSinglePrice" label="展示单价" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.actualSinglePrice == '暂无' ? '无' : $util.setMoney(scope.row.actualSinglePrice)}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="actualShowNum" label="展示次数" align="center"></el-table-column>
        <el-table-column prop="actualCountCost" label="总花费" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.actualCountCost == '暂无' ? '无' : $util.setMoney(scope.row.actualCountCost)}}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="biddingSuccessRate" label="竞价成功率" align="center"></el-table-column> -->
        <el-table-column label="用户反馈" align="center">
          <template slot-scope="scope">
            <el-link type="primary" :underline="false" style="margin-right: 5px;" @click="toFeedback(scope.row.id)">查看
            </el-link>
            <span v-if="parseInt(scope.row.feedBackNum) > 0" style="width: 16px;height: 16px;border-radius: 50%;background-color: red;
            display: inline-block;line-height: 16px;color: white;text-align: center;padding-left: 1%;">
              {{parseInt(scope.row.feedBackNum) > 8 ? scope.row.feedBackNum + '+' : parseInt(scope.row.feedBackNum)}}
              <!-- {{scope.row.feedBackNum}} -->
            </span>
          </template>
        </el-table-column>
       <el-table-column label="操作" fixed="right" width="130" align="center">
          <template slot-scope="scope">
            <el-link type="primary" :underline="false" style="margin-right: 10px;">编辑</el-link>
            <el-link type="primary" :underline="false">删除</el-link>
          </template>
        </el-table-column>
      </el-table>
      <!-- 广告管理列表分页 -->
      <div class="block">
        <el-pagination @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page="currpage"
          :page-sizes="[10, 50, 100]" :page-size="pagesize" background layout="total,prev, pager, next,sizes,jumper"
          :total="CountNum" style="margin-top:20px">
        </el-pagination>
      </div>
    </div>
    <!-- 广告管理结束 -->
  </div>
</template>

<script>
  import {
    advList,
    advInfo,
    advPriceEdit,
    advSuspend,
    advShowAmount
  } from '@/api/agentApi.js'
  export default {
    data() {
      return {
        adverData: [], // 广告列表
        dateList: ['近7天', '近14天', '近30天'], // Table选项
        pagesize: 10, // 每页显示条目个数
        currpage: 1, // 初始页
        CountNum: 0, // 总条数
        startAndEnd: false,
        start: true, //开始开关
        end: false, //暂停开关
        loading: false, //表格加载框
        dcLoading: false, //折线图加载框
        currentIndex: 0, // 索引
        serchList: {}, //  查询条件
        stateList: [], //  计划名称
      }
    },
    mounted() {
      // document.getElementById("mainRight").style.display = ''
      this.adverList({}, 1, this.pagesize) // 获取广告列表
      this.advShowAmount(7) // 默认近7天数据
    },
    methods: {
      drawChart(x, y) { //折线图展示
        const myChart = this.$echarts.init(document.getElementById('main'))
        const options = {
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: ['广告展示量']
          },
          xAxis: {
            type: 'category',
            data: x,
            axisTick: {
              show: false
            }
          },
          yAxis: {
            type: 'value',
            axisPointer: {
              show: true,
              triggerTooltip: false
            },
            axisTick: {
              show: false
            },
            splitLine: {
              lineStyle: {
                color: '#e8e8e8'
              }
            },
            // min: 1000,
            // max: 6000
          },
          series: [{
            name: '广告展示量',
            data: y,
            type: 'line',
            itemStyle: {
              color: '#1890FF'
            }
          }]
        }
        myChart.setOption(options)
        setTimeout(function() {
          window.onresize = function() {
            myChart.resize();
          }
        }, 200)
      },
      advShowAmount(num) { // 折线图日期数据
        this.dcLoading = true
        const data = {
          lastDays: num // 数据类型 (天)
        }
        advShowAmount(data).then(res => {
          var x = res.data.date // X轴数据（日期）
          var y = res.data.count // Y轴数据（展示总数）
          this.drawChart(x, y)
          this.dcLoading = false
        }).catch(err => {
          console.log(err)
        })
      },
      Atpitch(index) { // 折线图时间选择索引
        this.currentIndex = index
        switch (index) {
          case 0:
            this.advShowAmount(7)
            break
          case 1:
            this.advShowAmount(14)
            break
          case 2:
            this.advShowAmount(30)
            break
          default:
            this.advShowAmount(7)
        }
      },
      reset() { //重置按钮
        this.serchList = {}
        this.adverList({}, 1, this.pagesize)
      },
      adverList(serchJson, currpage, pageSize) { // 广告列表
        this.loading = true
        const data = serchJson
        data["page"] = currpage //	当前页数
        data["limit"] = pageSize // 每页条数
        data["stage"] = '100' // 审核状态(1待审核 2已通过 3已拒绝 100全部)
        advList(data).then(res => {
          this.adverData = res.data.List // 广告列表
          this.CountNum = parseInt(res.data.CountNum) // 总条数
          this.currpage = currpage //分页显示当前页
          this.pagesize = pageSize
          this.loading = false
        }).catch(err => {
          console.log(err)
        })
      },
      budGetClick(row) { //点击预算修改
        this.$prompt('请输入每日预算：', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern: /^[0-9]?((\d*(\.\d{1,3})$)|(\d{1,}$))/,
          inputErrorMessage: '今日预算必须是数字，并且最多精确到厘'
        }).then(({
          value
        }) => {
          let data = {
            everydayBudget: this.$util.getMoney(value), //每日预算
            singlePrice: row.singlePrice, //单次展示出价
            advId: row.id //广告ID
          }
          advPriceEdit(data).then(res => {
            if (res.status.code == 1) {
              this.$message({
                type: 'success',
                message: '每日预算修改成功！'
              });
              this.adverList({}, this.currpage, this.pagesize) // 获取广告列表
            } else {
              this.$message({
                type: 'error',
                message: res.status.msg
              });
            }
          }).catch(err => console.log(err))
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消修改！'
          });
        });
      },
      bidClick(row) { //点击出价修改
        this.$prompt('请输入价格', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          //inputPattern: /^[0-9]?((\d*(\.\d{1,3})$)|(\d{1,}$))/, //能输入小数点后三位 的正数
          inputPattern: /^[0-9-]?((\d*(\.\d{1,3})$)|(\d{1,}$))/,//能输入小数点后 两位 的正数 负数
          inputErrorMessage: '出价金额必须是数字,并且最多精确到厘'
        }).then(({
          value
        }) => {
          let data = {
            singlePrice: this.$util.getMoney(value), //单次展示出价
            everydayBudget: row.everydayBudget, //每日预算
            advId: row.id //广告ID
          }
          advPriceEdit(data).then(res => {
            if (res.status.code == 1) {
              this.$message({
                type: 'success',
                message: '出价修改成功！'
              });
              this.adverList({}, this.currpage, this.pagesize) // 获取广告列表
            } else {
              this.$message({
                type: 'error',
                message: res.status.msg
              });
            }
          }).catch(err => console.log(err))
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消修改！'
          });
        });
      },
      changge(val, item) { //广告暂停/开启
        let data = {
          advId: item.id,
          status: val ? '2' : '3'
        }
        advSuspend(data).then(res => {
          if (res.status.code == 1) {
            if (val) {
              this.$message.success("广告开启成功！")
            } else {
              this.$message.success("广告暂停成功！")
            }
            this.adverList({}, this.currpage, this.pagesize);
          } else {
            this.$message.error(res.status.msg)
          }
        }).catch(err => console.log(err))
      },
      toFeedback(id) { //跳转去反馈页面
        this.$router.push({
          name: 'feedbackList',
          params: {
            advId: id
          }
        })
      },
      handleCurrentChange(num) { // 分页
        this.adverList({}, num, this.pagesize)
      },
      handleSizeChange(num) {
        this.adverList({}, 1, num)
      }
    }
  }
</script>
<style lang="scss" scoped>
  .active {
    //检测人滑动点击css
    color: #1890FF;
    font-size: 14px;
  }

  .topBox {
    width: 100%;
    background: #FFFFFF;
    margin: 0 auto;
    margin-bottom: 20px;

    .chartTitle {
      height: 51px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #E8E8E8;

      .facility {
        font-size: 18px;
        line-height: 48px;
        border-bottom: 2px solid #1890FF;
        color: #1890FF;
        margin-left: 32px;
        font-family: 萍方-简;
        font-weight: normal;
      }

      ul {
        padding: 0;
        margin: 0;
        font-size: 14px;
        margin-right: 25px;

        li {
          float: left;
          list-style: none;
          padding-left: 33px;
        }

        li:hover {
          cursor: pointer
        }
      }
    }
  }

  .bottomBox {
    width: 100%;
    display: flex;
    background: #FFFFFF;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;

    .headerBox {
      display: flex;
      height: 52px;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #E8E8E8;

      p {
        line-height: 49px;
        font-size: 18px;
        font-family: 萍方-简;
        font-weight: normal;
        color: #1890FF;
        margin-left: 32px;
        border-bottom: 2px solid #1890FF;
      }

      .btn {
        display: flex;
        align-items: center;
        width: 109px;
        height: 32px;
        opacity: 1;
        border-radius: 2px;
      }
    }

    .query {
      width: 98%;
      height: 130px;
      display: flex;
      justify-content: space-between;
      padding-top: 20px;
      padding-left: 32px;
      color: #000000;
      font-family: 萍方-简;

      .queryLeft {
        width: 70%;
        display: flex;
        flex-wrap: wrap;

        div {
          margin-right: 20px;

          .input,
          .select {
            width: 15vw;
          }
        }
      }
    }

    .iconClick {
      color: #409EFF;
      margin-left: 3px;
      cursor: pointer;
    }
  }
</style>
